import React, { useEffect, useState, useContext } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { FaBarcode, FaSquarespace } from "react-icons/fa";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import {
  AiFillDashboard,
  AiFillHome,
  MdOutlineHelpCenter,
  BsFillPersonCheckFill,
  BsFillPersonPlusFill,
  FiMenu,
  RiSettingsLine,
  TbListDetails,
} from "react-icons/all";
import NavItem from "./NavItem";
import { MdHistory } from "react-icons/md";
import { RiWallet3Line } from "react-icons/ri";
import { AuthContext } from "src/context/Auth";
import Logo from "src/component/Logo";

const sections = [
  {
    items: [
      // {
      //   title: "Home",
      //   modules: "",
      //   icon: AiFillHome,
      //   href: "/",
      // },
      {
        title: "Dashboard",
        modules: "dashboard",
        icon: AiFillDashboard,
        href: "/dashboard",
      },
      {
        title: "Exchanges",
        modules: "exchange",
        icon: FaSquarespace,
        // img: <img src="images/dash1.png"/>,
        href: "/exchange-first",
      },
      {
        title: "Whitelist Details",
        modules: "",
        icon: TbListDetails,
        // img: <img src="images/dash1.png"/>,
        href: "/WhiteList-details",
      },

      // {
      //   title: "Subscription Management",
      //   modules: "subscription",
      //   icon: SubscriptionsIcon,
      //   // img: <img src="images/dash1.png"/>,
      //   href: "/subscription-management",
      // },
      {
        title: "Arbitrage",
        modules: "arbitrage",
        icon: FaBarcode,
        // img: "images/dash2.png",

        items: [
          {
            title: "Direct",
            href: "/direct",
          },
          // {
          //   title: "Intra Exchange",
          //   href: "/intra",
          // },
          {
            title: "Triangular",
            href: "/triangular",
          },
          // {
          //   title: "Loop",
          //   href: "/loop",
          // },
          // {
          //   title: "Inter Exchange",
          //   href: "/market",
          // },
        ],
      },
      {
        title: "My Wallet",
        modules: "mywallet",
        icon: RiWallet3Line,
        href: "/my-wallet",
      },
      {
        title: "Transaction History",
        modules: "",
        icon: MdHistory,
        href: "/transactions-history",
      },

      {
        title: "Settings",
        modules: "",
        icon: RiSettingsLine,
        href: "/setting",
      },
      // {
      //   title: "User Management",
      //   modules: "UserManagement",
      //   icon: BsFillPersonPlusFill,
      //   href: "/user-management",
      // },
      // {
      //   title: "Invite",
      //   modules: "invite",
      //   icon: BsFillPersonPlusFill,
      //   href: "/invite",
      // },
      // {
      //   title: "Subscriptions",
      //   modules: "",
      //   icon: BsFillPersonCheckFill,
      //   href: "/subscription",
      // },
      {
        title: "Static Content Management",
        modules: "staticContentManagement",
        icon: FiMenu,
        href: "/static-content-management",
      },
      {
        title: "FAQ Management",
        modules: "faqManagement",
        icon: MdOutlineHelpCenter,
        href: "/faqs",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 285,
    background: theme.palette.background.header,
  },
  desktopDrawer: {
    top: "16px",
    width: "250px",
    height: "calc(100% - 35px)",
    margin: "10px",
    background: "#2F49D1",
    boxShadow: "none",
    marginTop: "-6px",
    borderRadius: "10px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#F5C843",
    },
    "& svg": {
      color: "#F5C843",
      fontSize: "20px",
    },
  },
  btnBox: {
    position: "relative",
    left: "30%",
    bottom: "-250px",
  },
  logoutButton: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    position: "absolute",
    bottom: "19px",
    left: "17px",
    background: "transparent",
    fontWeight: "400",
    fontSize: "13px",
  },
  sideMenuBox: {
    "& .MuiCollapse-wrapperInner": {
      marginLeft: "45px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const location = useLocation();
  let permission = auth.userData.permissions;
  let connectedExchange = auth.userData?.connectedExchange
    ? auth.userData?.connectedExchange?.length
    : 0;

  const history = useHistory();
  const [isLogout, setIsLogout] = useState(false);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]); // eslint-disable-line

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2} pb={2}>
          <Box className="leftBox" style={{ padding: "0 20px" }} mb={2}>
            <Link to="/dashboard">
              <Logo style={{ width: "auto", maxWidth: "179px" }} />
            </Link>
          </Box>
          <Box className="sideMenuBox">
            {sections.map((section, i) => {
              // ****************************************

              let item;
              let item1 = section.items.map((data, i) => {
                // eslint-disable-next-line
                if (section?.items[i]?.modules == "") {
                  return section.items[i];
                }
                if (
                  section?.items[i]?.modules == "dashboard" && // eslint-disable-line
                  permission?.dashboard
                ) {
                  return section.items[i];
                } else if (
                  section?.items[i]?.modules == "exchange" && // eslint-disable-line
                  permission?.exchange
                ) {
                  // eslint-disable-next-line
                  if (connectedExchange == 0) {
                    return section.items[i];
                  } else {
                    return {
                      title: "Exchanges",
                      modules: "exchange",
                      icon: FaSquarespace,
                      // img: <img src="images/dash1.png"/>,
                      href: "/exchange",
                    };
                  }
                } else if (
                  section?.items[i]?.modules == "mywallet" && // eslint-disable-line
                  permission?.mywallet
                ) {
                  return section.items[i];
                } else if (
                  section?.items[i]?.modules == "subscription" && // eslint-disable-line
                  permission?.subscription
                ) {
                  return section.items[i];
                } else if (
                  section?.items[i]?.modules == "staticContentManagement" && // eslint-disable-line
                  permission?.staticContentManagement
                ) {
                  return section.items[i];
                } else if (
                  section?.items[i]?.modules == "arbitrage" && // eslint-disable-line
                  permission?.arbitrage
                ) {
                  return section.items[i];
                } else if (
                  section?.items[i]?.modules == "subscription" && // eslint-disable-line
                  permission?.subscription
                ) {
                  return section.items[i];
                } else {
                  return false;
                }
              });
              for (let k = 1; k < item1.length + 1; k++) {
                if (!item1[k]) {
                  item1.splice(k, 1);
                }
              }
              for (let l = 1; l < item1.length + 1; l++) {
                if (!item1[l]) {
                  item1.splice(l, 1);
                }
              }
              for (let m = 1; m < item1.length + 1; m++) {
                if (!item1[m]) {
                  item1.splice(m, 1);
                }
              }
              // eslint-disable-next-line
              if (auth.userData.userType == "SUBADMIN") {
                item = item1; // eslint-disable-line
              } else {
                item = section.items; // eslint-disable-line
              }
              // ****************************************
              return (
                <List
                  key={`menu${i}`}
                  subheader={
                    <ListSubheader disableGutters disableSticky>
                      {section.subheader}
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    img: section.img,
                    items: item,
                    pathname: location.pathname,
                  })}
                </List>
              );
            })}
          </Box>
        </Box>

        <Button
          onClick={() => setIsLogout(true)}
          className={classes.logoutButton}
        >
          <ExitToAppIcon
            style={{
              marginRight: "16px",
            }}
          />
          &nbsp; Logout
        </Button>

        {isLogout && (
          <Dialog
            maxWidth="sm"
            fullWidth
            className={classes.dailogOpen}
            open={isLogout}
            // TransitionComponent={Transition}
            // keepMounted
            onClose={() => setIsLogout(false)}
          >
            <DialogContent>
              <Box className={classes.dialougTitle} align="center">
                <Typography variant="h6" color="primary">
                  Are you sure want to logout?
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions
              style={{
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <Box mt={2}>
                <Button
                  className="modelbtn"
                  color="primary"
                  variant="contained"
                  style={{ padding: "8px 21px" }}
                  onClick={() => setIsLogout(false)}
                >
                  No
                </Button>
                &nbsp; &nbsp;
                <Button
                  className="modelbtnyes"
                  style={{
                    padding: "8px 21px",
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    window.localStorage.removeItem("token");
                    auth.userLogIn(false, null);
                    history.push("/");
                  }}
                >
                  Yes
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        )}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
