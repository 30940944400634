import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  makeStyles,
  IconButton,
  Typography,
  SvgIcon,
  Toolbar,
  AppBar,
  Hidden,
  Avatar,
  Grid,
  Box,
} from "@material-ui/core";
import { Menu as MenuIcon } from "react-feather";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
import Logo from "src/component/Logo";
import { BiBell } from "react-icons/bi";
import SettingsContext from "src/context/SettingsContext";
import { FiSun } from "react-icons/fi";
import { FaRegMoon } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    padding: "7px 30px 7px 30px",

    background: "transparent !important",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px 0px 20px",
    },
  },

  toolbarLight: {
    background: "#FFF !important",
  },

  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  mainheader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",

    "& svg": {
      color: theme.palette.text.primary,
    },
    "& .leftBox": {
      width: "306px",
      [theme.breakpoints.down("md")]: {
        width: "200px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "150px",
      },
      "& img": {
        width: "150px",
        [theme.breakpoints.down("xs")]: {
          width: "104px",
          paddingLeft: "0 !important",
        },
      },
    },
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const themeSeeting = useContext(SettingsContext);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };
  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    if (auth?.userData) {
      setProfileData({
        profilePic: auth?.userData?.profilePic,
      });
    } // eslint-disable-next-line
  }, [auth?.userData]);

  return (
    <AppBar
      elevation={0}
      className={clsx(classes.root, className)}
      color="inherit"
      style={
        themeSeeting.settings.theme === "DARK"
          ? { backgroundColor: "rgb(36 35 43)" }
          : { backgroundColor: "#FFF" }
      }
      {...rest}
    >
      <Toolbar
        className={
          themeSeeting.settings.theme === "DARK"
            ? `${classes.toolbar}`
            : `${classes.toolbarLight}`
        }
      >
        <Hidden lgUp>
          <IconButton
            color="#00e0b0"
            onClick={onMobileNavOpen}
            style={{ padding: "0px" }}
          >
            <SvgIcon>
              <MenuIcon style={{ color: "#FEB53D", fontSize: "25px" }} />
            </SvgIcon>
          </IconButton>
        </Hidden>
        &nbsp; &nbsp;
        <Box className={classes.mainheader}>
          <Grid container alignItems="center">
            <Grid item lg={3} md={3} sm={4} xs={6}>
              <Box className="leftBox">
                <Link to="/dashboard">
                  <Logo width="125" />
                </Link>
              </Box>
            </Grid>
            <Hidden xsDown>
              <Grid lg={4} md={6} sm={5}>
                {/* <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Please enter Password"
                  type="text"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={{ paddingLeft: "3px" }}
                      >
                        <FiSearch style={{ fontSize: "20px" }} />
                      </InputAdornment>
                    ),
                  }}
                /> */}
              </Grid>
            </Hidden>
            <Grid lg={5} md={3} sm={3} xs={6}>
              <Box className="displayEnd">
                <Box className="themeButton">
                  {themeSeeting.settings.theme === "DARK" ? (
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        changeTheme("LIGHT");
                      }}
                    >
                      <FiSun />
                    </IconButton>
                  ) : (
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        changeTheme("DARK");
                      }}
                    >
                      <FaRegMoon />
                    </IconButton>
                  )}
                </Box>
                <IconButton onClick={() => history.push("/notification")}>
                  <BiBell />
                </IconButton>
                <Box
                  ml={2}
                  onClick={() => history.push("/profile")}
                  style={{ cursor: "pointer" }}
                >
                  <Avatar
                    src={
                      profileData.profilePic
                        ? profileData.profilePic
                        : "images/editprofile.png"
                    }
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const auth = useContext(AuthContext);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Hidden xsDown>
          <Box>
            <Typography variant="h5">NFT Marketplace</Typography>
            <Typography variant="body1" style={{ color: "#ffffff9c" }}>
              example@gmail.com
            </Typography>
          </Box>
        </Hidden>
        &nbsp; &nbsp;
        <Avatar
          src={
            auth?.userData?.profilePic
              ? `${auth?.userData?.profilePic}`
              : "https://picsum.photos/533/357"
          }
          className={classes.avatar}
          // onClick={() => history.push("/admin-profile")}
        />
      </Box>
    </>
  );
}
