import apiConfigs from "src/apiConfig/ApiConfig";
import axios from "axios";


export function sortAddress(add) {
  const sortAdd = `${add?.slice(0, 6)}...${add?.slice(add.length - 4)}`;
  return sortAdd;
}
export function sortAddressWalletDeposite(add) {
  const sortAdd = `${add?.slice(0, 40)}...${add?.slice(add?.length - 4)}`;
  return sortAdd;
}

export function getSigner(library, account) {
  return library.getSigner(account).connectUnchecked();
}
export const handleNegativeValue = (event) => {
  const {
    key,
    target: { value, selectionStart },
  } = event;
  const newValue =
    value?.slice(0, selectionStart) + value?.slice(selectionStart + 1);
  const parsedValue = parseFloat(newValue);
  if (
    ["ArrowUp", "ArrowDown", "-"].includes(key) &&
    (isNaN(parsedValue) || parsedValue < 0)
  ) {
    event.preventDefault();
  }
};

export function getProviderOrSigner(library, account) {
  return account ? getSigner(library, account) : library;
}
export function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string?.slice(1);
}

export const getCoinImageDatahandler = async (token) => {
  try {
    const res = await axios({
      method: "GET",
      url: apiConfigs.get_wallet_coinImageData,
    });
    if (res.data.responseCode === 200) {
      return res?.data?.result;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const ExchangeArray = [
  { img: "images/Kraken.jpeg", coinName: "Kraken" },
  { img: "images/binanaceImg.png", coinName: "Binance" },
  { img: "images/Mexcs.jpeg", coinName: "Mexc" },
];
export const setCryptoDecimals = (amt) => {
  amt = exponentialToDecimal(amt || 0);

  amt = amt?.replace(",", "");
  let arr = amt?.toString().split(".");

  if (arr.length > 1) {
    if (arr[1].length > 8) {
      return numberWithCommas(
        // exponentialToDecimal(parseFloat(amt).toFixed(8)).toString(),
        exponentialToDecimal(parseFloat(amt)).toString()
      ).toString();
    } else {
      return numberWithCommas(amt).toString();
    }
  } else {
    if (amt) {
      return numberWithCommas(amt).toString();
    }
    return "0";
  }
};
export const exponentialToDecimal = (exponential) => {
  let decimal = exponential?.toString()?.toLowerCase();
  if (decimal?.includes("e+")) {
    const exponentialSplitted = decimal?.split("e+");
    let postfix = "";
    for (
      let i = 0;
      i <
      +exponentialSplitted[1] -
      (exponentialSplitted[0].includes(".")
        ? exponentialSplitted[0].split(".")[1].length
        : 0);
      i++
    ) {
      postfix += "0";
    }
    const addCommas = (text) => {
      let j = 3;
      let textLength = text.length;
      while (j < textLength) {
        text = `${text?.slice(0, textLength - j)},${text?.slice(
          textLength - j,
          textLength
        )}`;
        textLength++;
        j += 3 + 1;
      }
      return text;
    };
    decimal = addCommas(exponentialSplitted[0].replace(".", "") + postfix);
  }
  if (decimal?.toLowerCase().includes("e-")) {
    const exponentialSplitted = decimal?.split("e-");
    let prefix = "0.";
    for (let i = 0; i < +exponentialSplitted[1] - 1; i++) {
      prefix += "0";
    }
    decimal = prefix + exponentialSplitted[0].replace(".", "");
  }
  return decimal;
};
const numberWithCommas = (x) => {
  let str = toFixedFunction(x, 8);

  let arr = str.split(".");

  let numbers = arr[0];
  let decimalNum = "";
  if (arr.length > 1) {
    decimalNum = arr[1];
    return `${numbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${decimalNum}`;
  } else {
    return numbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};
export const toFixedFunction = (amt, decimals) => {
  let str = amt?.toString();
  if (str?.includes(".")) {
    str = str?.slice(0, str.indexOf(".") + (decimals + 1));
  }
  return str;
};
export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / ((1000 / 60) * 60 * 24)),
        hours: Math.floor((difference / ((1000 / 60) * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};

export const ExchangeLogo = [
  {
    img: "images/ExchangeLogo/binance.jpg",
    title: "binance",
  },
  {
    img: "images/ExchangeLogo/bitstamp.png",
    title: "bitstamp",
  },
  {
    img: "images/ExchangeLogo/coinbasepro.png",
    title: "Mexc",
  },
  {
    img: "images/ExchangeLogo/cryptocom.png",
    title: "cryptocom",
  },
  {
    img: "images/ExchangeLogo/ftxus.png",
    title: "ftxus",
  },
  {
    img: "images/ExchangeLogo/gemini.png",
    title: "gemini",
  },
  {
    img: "images/ExchangeLogo/cexio.png",
    title: "cexio",
  },
  {
    img: "images/ExchangeLogo/huobi.png",
    title: "huobi",
  },
  {
    img: "images/ExchangeLogo/kraken.png",
    title: "kraken",
  },
  {
    img: "images/ExchangeLogo/kucoin.png",
    title: "kucoin",
  },
];
