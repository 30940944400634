import React, { useContext } from "react";
import { Typography, makeStyles, Box, Container } from "@material-ui/core";
import SettingsContext from "src/context/SettingsContext";
import PropTypes from "prop-types";
import TopBar from "./TopBar";
// import TopBar from "../DashboardLayout/TopBar";
const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    position: "relative",
    height: "100vh",
    backgroundColor: "#fff",
  },
  root1: {
    backgroundColor: "#000",
    overflow: "hidden",
    position: "relative",
    height: "100vh",
  },
  loginLayoutBox: {
    display: "flex",
    position: "relative",
    justifyContent: "start",
    alignItems: "end",
    height: "100vh",
    backgroundImage: "url(./images/login_banner.jpeg)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    objectFit: "cover !important",
  },
  loginContentLayoutBox: {
    position: "absolute",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    height: "100vh",
    zIndex: "9",
    right: 0,
    width: "45%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "80px 0 50px",
    },
  },
  contentBox: {
    position: "absolute !important",
    zIndex: "9",
    bottom: "80px !important",
    "& h1": {
      color: "#fff",
      marginBottom: "8px",
    },
    "& h6": {
      color: "#fff",
      maxWidth: "700px",
      fontWeight: "400",
      fontSize: "14px",
    },
  },
}));

export default function LoginLayout({ children }) {
  const classes = useStyles();
  const themeSeeting = useContext(SettingsContext);
  return (
    <div
      className={
        themeSeeting.settings.theme === "DARK"
          ? `${classes.root1}`
          : `${classes.root}`
      }
    >
      <TopBar />
      <Box
        className={classes.loginLayoutBox}
        style={
          themeSeeting.settings.theme === "DARK"
            ? { backgroundImage: "url(./images/login_banner.jpeg)" }
            : { backgroundImage: "url(./images/login_banner.jpeg)" }
        }
      >
        <Box className={classes.contentBox}>
          <Container maxWidth="false">
            <Typography variant="h1" color="primary">
              Welcome Back
            </Typography>

            <Typography variant="h6">
              Lorem ipsum dolor sit amet consectetur. Interdum molestie lectus
              posuere nibh suscipit mattis pretium nulla. Arcu odio sed sem
              vestibulum.
            </Typography>
          </Container>
        </Box>

        <Box
          className={classes.loginContentLayoutBox}
          style={
            themeSeeting.settings.theme === "DARK"
              ? {
                  background: "#272932",
                }
              : { backgroundColor: "white" }
          }
        >
          <Container maxWidth="sm">
            <Box style={{ width: "100%" }}>{children}</Box>
          </Container>
        </Box>
      </Box>
    </div>
  );
}
LoginLayout.propTypes = {
  children: PropTypes.node,
};
