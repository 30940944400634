import React from "react";

import {
  Box,
  Container,
  Typography,
  makeStyles,
  ListItem,
  List,
  Link,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    // padding: "50px 0px 0",
    zIndex: "2",
    overflow: " hidden",
    background: "#2A2A2B",

    "& .informationBox": {
      marginRight: "108px",
      [theme.breakpoints.down("xs")]: {
        marginRight: "0px",
      },
    },
    "& .copy": {
      padding: "1px 0",
      background: "#000000",
      textAlign: "center",
      fontWeight: "300",
      height: "51px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& ul": {
      paddingLeft: "0",
      "& li": {
        paddingLeft: "0",
        alignItems: "center",
        color: theme.palette.text.gray,
        fontSize: "14px",
        fontWeight: "300",
        "& svg": {
          marginRight: "10px",
          color: "#FEB53D",
          fontSize: "15px",
        },
      },
    },

    "& a": {
      color: "#fff",
      display: "flex",
      fontSize: "16px",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: "400",
      paddingLeft: "0px",
      paddingRight: "0px",
      textDecoration: "none",
      padding: "0px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "11px",
        justifyContent: "start",
        padding: "5px 0",
      },
      "& :hover": {
        color: "#FEB53D",

        textDecoration: "none",
        "& svg": {
          color: "red",
          fontSize: "15px",
        },
      },
    },
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.footerSection}>
        {/* <Container maxWidth="lg">
          <Box style={{ borderBottom: "1px solid gray" }}>
            <Box align="center" className="displayCenter">
              {" "}
              <RouterLink to="/">
                <img src="images/logo.svg" alt="" style={{ width: "150px" }} />{" "}
                <br />
              </RouterLink>
            </Box>
            <Container maxWidth="md">
              <Grid container spacing={2}>
                <Grid item xs={6} sm={3} md={3}>
                  <List>
                    <ListItem to="/" component={RouterLink}>
                      Home
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <List>
                    <ListItem to="/" component={RouterLink}>
                      Supported Exchange
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <List>
                    <ListItem to="/" component={RouterLink}>
                      Supported Cryptocurrencies
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  {" "}
                  <List>
                    <ListItem to="/" component={RouterLink}>
                      Arbitrage Trading Strategies
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Container>
          </Box>

          <Box className="displayCenter">
            <Box className="displayStart informationBox" style={{}}>
              <FiMail style={{ color: "#fff" }} /> &nbsp; &nbsp;
              <List>
                <ListItem>
                  <Link href=" Support@acn.com">
                    {user?.userData?.userType === "Admin" ? (
                      <> {user?.userData?.email}</>
                    ) : (
                      <>mailto:support@hovr.com</>
                    )}
                    Support@acn.com
                  </Link>
                </ListItem>
              </List>
            </Box>

            <Box className="displayStart">
              <PhoneIcon style={{ color: "#fff" }} /> &nbsp; &nbsp;
              <Typography
                variant="body2"
                style={{ color: "#fff", cursor: "pointer" }}
              >
                +91 1234567890
              </Typography>
            </Box>
          </Box>
        </Container> */}
        <Container>
          <Box
            align="center"
            alignItems="center"
            position="relative"
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <Box className="displayStart">
              <List>
                <ListItem
                  to="/term-and-condition"
                  component={RouterLink}
                  style={{ color: "#FEB53D" }}
                >
                  Terms
                </ListItem>
              </List>{" "}
              &nbsp; &nbsp;
              <List>
                <ListItem
                  to="/privacy-policy"
                  component={RouterLink}
                  style={{ color: "#FEB53D" }}
                >
                  Privacy Policy
                </ListItem>
              </List>
              &nbsp; &nbsp;
              <List>
                <ListItem
                  to="/FAQ"
                  component={RouterLink}
                  style={{ color: "#FEB53D" }}
                >
                  FAQ
                </ListItem>
              </List>
              &nbsp; &nbsp;
              <List>
                <ListItem>
                  <Link href=" Support@acn.com">
                    {/* {user?.userData?.userType === "Admin" ? (
                      <> {user?.userData?.email}</>
                    ) : (
                      <>mailto:support@hovr.com</>
                    )} */}
                    Support@acn.com
                  </Link>
                </ListItem>
              </List>
            </Box>
            <Typography
              variant="body1"
              style={{ color: "#fff", textAlign: "center" }}
            >
              © 2023-2024 Arbitrage Exchange
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
}
