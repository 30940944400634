import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  MenuItem,
  Box,
  Container,
  Menu,
  Paper,
  Dialog,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import { AuthContext } from "src/context/Auth";
const headersData = [
  // {
  //   label: "Add Branding",
  //   href: "/add-brand",
  // },
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Supported Exchange",
    href: "/faqs",
  },
  {
    label: "Supported Cryptocurrencies",
    href: "/faqs",
  },
  {
    label: "Arbitrage Trading Strategies",
    href: "/terms-conditions",
  },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "13px",
    lineHeight: "24px",
    fontWeight: "400",
    borderRadius: 0,
    minWidth: "auto",
    color: "#FFFFFF",
    // height: "31px",
    padding: "0px 7px",
    // letterSpacing: "1px",
    // marginLeft: "15px",
    textDecoration: " none",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      color: "#FEB53D",
      fontWeight: "600",
      textTransform: "uppercase",
    },
    "&:hover": {
      color: "#FEB53D",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    // background: theme.palette.background.blur,
    // border: "2px solid rgba(0, 0, 0, 0.025)",
    // backdropFilter: "blur(4px)",
    // borderRadius: 15,
    // padding: " 5px 0px",
    // marginTop: 20,
    "& .MuiButton-containedPrimary:hover": {
      color: "#fff",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    // paddingLeft: "10px",
    width: "140px",
    // marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px 20px 20px",
    height: "100%",
    background: theme.palette.background.header,
    color: "#fff",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    // position: "absolute",
    // top: "0px",
    // right: "-18px",
    marginLeft: "0px !important",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
      // width: "75px",
    },
  },
  menuMobile: {
    // fontSize: "16px",
    // fontWeight: "400",
    // fontStyle: "normal",
    // letterSpacing: "-0.6px",
    // lineHeight: "1.75",
    // color: "#000",
    // paddingLeft: 0,
    // borderBottom: "1px solid #3e3e3e",
    padding: "16px",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },

  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  themeButton: {
    // "& .MuiIconButton-root": {
    //   background:"red !important",
    // },
  },
  search: {
    height: "40px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#DAF4FF",
    border: "1px solid #fff",

    marginLeft: 20,
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: "0px 9px",
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
    width: "100%",
  },
  inputInput: {
    padding: "8px 6px 8px 0px",
    fontSize: "12px",
    marginTop: "-2px",
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "#000",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  menuButton1: {
    paddingLeft: "0",
    display: "none",
  },
  searcBox: {
    backgroundColor: "#DAF4FF",
    // border: "1px solid #daf4ff",
    borderRadius: " 50px",
  },
  menuMobile1: {
    // marginLeft: "10px",
    padding: "15px 0",
    "& h4": {
      fontSize: "14px !important",
      lineHeight: " 17px",
      color: theme.palette.text.main,
      margin: "0 8px",
      fontWeight: "400",
      [theme.breakpoints.only("xs")]: {
        fontSize: "12px !important",
      },
    },
    "& svg": {
      color: theme.palette.text.main,
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "&:hover": {
      // backgroundColor: theme.palette.background.card,
      backgroundColor: "transparent",
      // borderRadius: "40px",
    },
    "& figure": {
      margin: 0,
      width: 40,
      height: 40,
      borderRadius: "50px",
      overflow: "hidden",
      display: "flex",
      justifyContent: " center",
      alignItems: "center",
      "& img": {
        width: "auto",
        height: "auto",
        maxWidth: "100%",
        // maxHeight: "100%",
      },
    },
  },
  menuMobile2: {
    "& h4": {
      fontSize: "14px",
      lineHeight: " 17px",
      color: theme.palette.background.dark,
      margin: "0 5px",
      whiteSpace: "pre",
      fontWeight: "300",
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "& svg": {
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: "#ff3965",
      // borderRadius: "40px",
    },
  },
  searchdiaogBox: {
    "& .MuiDialogContent-root": {
      minHeight: "calc(100vh - 100px)",
      [theme.breakpoints.only("xs")]: {
        padding: "20px 0 !important",
      },
    },
    "& .MuiDialog-paperScrollPaper": {
      overflowY: "auto",
    },
  },
  headerBox: {
    background: "transparent",
  },
}));

export default function Header() {
  const classes = useStyles();
  const history = useHistory();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const auth = useContext(AuthContext);
  useEffect(() => {
    if (!auth.userLoggedIn) {
      history.push("/");
    } // eslint-disable-next-line
  }, [auth.userLoggedIn]);
  const handleClose4 = () => {
    setAnchorEl1(null);
  };

  const {
    menuButton1,
    divstake,
    toolbar,
    drawerContainer,
    logoDrawer,
    mainHeader,
  } = useStyles();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  // const handleToggle = (name) => {
  //   setOpen1({ ...open1, [name]: !open1[name] });
  // };

  const StyledMenu = withStyles({
    paper: {
      marginTop: "2px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const displayDesktop = () => {
    return (
      // <Container maxWidth="lg" className="p-0">
      <Toolbar className={toolbar}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          {femmecubatorLogo}

          {/* {SearchBoxx} */}
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {/* <Box className="themeButton">
            {themeSeeting.settings.theme === "DARK" ? (
              <IconButton
                variant="contained"
                className="searchiconBox1"
                color="primary"
                style={{ background: "rgba(255, 255, 255, 0.05)" }}
                onClick={() => {
                  changeTheme("LIGHT");
                }}
              >
                <FiSun style={{ color: "#fff" }} />
              </IconButton>
            ) : (
              <IconButton
                variant="contained"
                className="searchiconBox"
                color="primary"
                style={{ background: "rgba(13, 13, 13, 0.08)" }}
                onClick={() => {
                  changeTheme("DARK");
                }}
              >
                <FaRegMoon style={{ color: "#fff" }} />
              </IconButton>
            )}
          </Box> */}
          {getMenuButtons()}
          {/* {user?.isLogin ? (
            <Button
              aria-label="delete"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick1}
              className={menuMobile1}
              style={{ marginLeft: "10px" }}
              size="small"
              color="primary"
            >
              <figure
                style={
                  user?.isLogin
                    ? { height: "40px", width: "40px" }
                    : { width: "0px" }
                }
              >
                <img
                  src={
                    user?.userData?.profilePic
                      ? user?.userData?.profilePic
                      : "/images/idicon.svg"
                  }
                  alt=""
                />
              </figure>
              <Typography
                variant="h4"
                color="primary"
                title={
                  user?.userData?.name
                    ? user?.userData?.name
                    : user?.userData?.walletAddress
                }
              >
                {" "}
                {user?.userData?.name
                  ? user?.userData?.name.slice(0, 5) + ".."
                  : sortAddress(user?.userData?.walletAddress)}
              </Typography>
              <BsFillCaretDownFill style={{ fontSize: "16px" }} />
            </Button>
          ) : (
            <>
            
            </>
          )} */}
          &nbsp;
          {/* <Box
            className="themeButton"
            style={{ display: "flex", margin: "7px" }}
          >
            <IconButton
              style={{ background: "rgba(255, 255, 255, 0.05)" }}
              // style={{background:"red"}}
              className="searchiconBox"
              onClick={() => setDialogOpen(true)}
            >
              <SearchIcon />
            </IconButton>
          </Box> */}
          <Box className={divstake}>
            <StyledMenu
              id="simple-menu"
              disableScrollLock={true}
              anchorEl={anchorEl1}
              keepMounted
              open={Boolean(anchorEl1)}
              onClose={handleClose4}
            >
              <MenuItem
                onClick={() => {
                  history.push("/profile");
                }}
              >
                Profile
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/create");
                }}
              >
                Create NFT
              </MenuItem>

              {/* <MenuItem
                onClick={() => {
                  user.logoutHandler();
                  setAnchorEl1();
                }}
              >
                Disconnect
              </MenuItem> */}
            </StyledMenu>
          </Box>
        </Box>
      </Toolbar>
      // {/* </Container> */}
    );
  };

  const displayMobile = () => {
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    //mobile
    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          {/* <Box className="themeButton">
            {themeSeeting.settings.theme === "DARK" ? (
              <IconButton
                variant="contained"
                className="searchiconBox1"
                color="primary"
                style={{ background: "rgba(255, 255, 255, 0.05)" }}
                onClick={() => {
                  changeTheme("LIGHT");
                }}
              >
                <FiSun style={{ color: "#fff" }} />
              </IconButton>
            ) : (
              <IconButton
                variant="contained"
                className="searchiconBox"
                color="primary"
                style={{ background: "rgba(13, 13, 13, 0.08)" }}
                onClick={() => {
                  changeTheme("DARK");
                }}
              >
                <FaRegMoon style={{ color: "#fff" }} />
              </IconButton>
            )}
          </Box> */}
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/logo.svg" alt="" />
            {getDrawerChoices()}
            <div>
              {/* {user?.isLogin ? (
                <IconButton
                  aria-label="delete"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick1}
                  className={menuMobile1}
                  size="small"
                  style={{ padding: "16px 0px" }}
                >
                  <figure
                    style={
                      user?.isLogin
                        ? { height: "40px", width: "40px" }
                        : { width: "0px" }
                    }
                  >
                    <img
                      src={
                        user?.userData?.profilePic
                          ? user?.userData?.profilePic
                          : "/images/Profile.png"
                      }
                      alt=""
                    />
                  </figure>
                  <Typography
                    variant="h4"
                    color="primary"
                    title={
                      user?.userData?.name
                        ? user?.userData?.name
                        : user?.userData?.walletAddress
                    }
                  >
                    {" "}
                    {user?.userData?.name
                      ? user?.userData?.name.slice(0, 5) + ".."
                      : sortAddress(user?.userData?.walletAddress)}
                  </Typography>
                  <BsFillCaretDownFill
                    style={{
                      color: "#000",
                      fontSize: "16px",
                      marginLeft: "8px",
                    }}
                  />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="delete"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={() => setUpdateMinSatkeOpen(true)}
                  className={menuMobile1}
                  size="small"
                >
                  <figure
                    style={
                      user?.isLogin
                        ? { height: "40px", width: "40px" }
                        : { width: "0px" }
                    }
                  >
                    <img
                      src={
                        user?.userData?.profilePic
                          ? user?.userData?.profilePic
                          : "/images/Profile.png"
                      }
                      alt=""
                    />
                  </figure>
                 

                  <Button
                    variant="contained"
                    className="ecoButton"
                    to="/"
                    color="primary"
                    component={Link}
                    style={{ marginLeft: "15px", whiteSpace: "pre" }}
                  >
                    Login
                  </Button>
                </IconButton>
              )} */}

              <Box className={divstake}>
                <Menu
                  id="simple-menu"
                  disableScrollLock={true}
                  anchorEl={anchorEl1}
                  keepMounted
                  open={Boolean(anchorEl1)}
                  onClose={handleClose4}
                >
                  <MenuItem
                    onClick={() => {
                      history.push("/profile");
                    }}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push("/create");
                    }}
                  >
                    Create NFT
                  </MenuItem>
                  {/**
                    <MenuItem
                    onClick={() => {
                      history.push("/create-nfttype");
                    }}
                  >
                    Create Physical NFT
                  </MenuItem>
              */}

                  {/* <MenuItem
                    onClick={() => {
                      user.logoutHandler();
                      setAnchorEl1();
                    }}
                  >
                    Logout
                  </MenuItem> */}
                </Menu>
              </Box>
            </div>
          </div>
        </Drawer>

        <Box display="flex" justifyContent="space-between">
          {femmecubatorLogo}
        </Box>
        <Box display="flex" alignItems="center">
          {/* <IconButton
            onClick={() => setDialogOpen(true)}
            className="searchiconBox"
          >
            <SearchIcon />
          </IconButton> */}
          {/* <Box className="themeButton">
            {themeSeeting.settings.theme === "DARK" ? (
              <IconButton
                variant="contained"
                color="primary"
                style={{ background: "rgba(255, 255, 255, 0.05)" }}
                onClick={() => {
                  changeTheme("LIGHT");
                }}
              >
                <FiSun />
              </IconButton>
            ) : (
              <IconButton
                variant="contained"
                color="primary"
                style={{ background: "rgba(13, 13, 13, 0.08)" }}
                onClick={() => {
                  changeTheme("DARK");
                }}
              >
                <FaRegMoon />
              </IconButton>
            )}
          </Box> */}
          {/* <IconButton
            className={drawericon}
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon
              width="60px"
              height="60px"
              style={{ color: "#FEB53D", fontSize: "26px" }}
            />
          </IconButton> */}
        </Box>
      </Toolbar>
    );
  };
  //mobile end
  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton1,
            }}
          >
            {/* <MenuItem className={menuMobile}>{label}</MenuItem> */}
          </Button>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  // const SearchBoxx = (
  //   <SearchBox
  //     search={search}
  //     inputRoot={inputRoot}
  //     inputInput={inputInput}
  //     searchIcon={searchIcon}
  //   />
  // );

  const getMenuButtons = (activeClassName) => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          {/* <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton,
            }}
          >
            {label}
          </Button> */}

          {/* <NavLink
            exact
            // to={`${href}`}
            {...{
              key: label,
              color: "inherit",
              to: href,
              // component: Link,
              className: menuButton,
              activeClassName: "active",
            }}
          >
            {" "}
            {label}
          </NavLink> */}
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        className={classes.headerBox}
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ border: "none" }}
      >
        <Container maxWidth="lg">
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
      {dialogOpen && (
        <Paper>
          <Dialog
            fullWidth
            maxWidth="lg"
            className={classes.searchdiaogBox}
            style={{
              position: "absolute",
              top: "10%",
              // minHeight: "695px",
            }}
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
          >
            <IconButton
              className="closeButton"
              onClick={() => setDialogOpen(false)}
            >
              <CloseIcon style={{ color: "#AAAAAA" }} />
            </IconButton>

            <Box className="dialogBoxHeight">
              <CloseIcon style={{ color: "#AAAAAA" }} />
            </Box>
          </Dialog>
        </Paper>
      )}
    </>
  );
}
